.login-form-container {
    margin: 0 auto;
    clear: both;
}
.login-content {
    max-width: 865px;
    width: 100%;
}
#login-form-smww {
    width: 100%;
    max-width: 1170px;
    height: auto;
    margin: 0 auto;
}

#login-form-smww .left-center {
    margin-left: 1%;
    max-width: 865px;
    width: 73%;
	float: left;
}

.element-invisible {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
}

ul.primary {
    border-bottom: 1px solid #bbb;
    border-collapse: collapse;
    height: auto;
    line-height: normal;
    list-style: none;
    margin: 5px;
    padding: 0 0 0 1em;
    white-space: nowrap;
}

ul.primary li.active a {
    background-color: #fff;
    border: 1px solid #bbb;
    border-bottom: 1px solid #fff;
}	

ul.primary li {
    display: inline;
}

.tabs {
    margin-top: 12px !important;
}

#login-form-smww .right {
    max-width: 280px;
    width: 24%;
    height: auto;
    float: right;
}

.option {
    display: initial;
}

#login-form-smww .left {
    float: left;
    height: auto;
    margin-right: 2%;
    max-width: 195px;
    width: 18%;
    margin-left: 1%;
}

div.warning, table tr.warning {
    background-color: #fffce5;
}

div.warning, .warning {
    color: #840;
}

div.warning {
    background-image: url(https://www.sportsmanagementworldwide.com/misc/message-24-warning.png);
    border-color: #ed5;
}

div.messages {
	
    background-position: 8px 8px;
    background-repeat: no-repeat;
    border: 1px solid;
    margin: 6px 6px;
	margin-right:12px;				  
    padding: 10px 10px 10px 50px;
}

div.set-message-error {
    margin-right: 10px;
    margin-top: 12px !important;
}

.form-submit, .form-submit:visited {
    background-color: #004b85 !important;
}

.form-submit, .form-submit:visited, .medium.form-submit, .medium.form-submit:visited {
    font-size: 15px;
    line-height: 1;
}
.login-form-submit {
    line-height: 24px !important;
    color: #ffffff !important;
    margin-right:10px;
}
.form-submit, .form-submit:visited {
    background: #222 url(https://www.sportsmanagementworldwide.com/sites/all/themes/smww/css/overlay.png) repeat-x;
    display: inline-block;
    padding: 5px 10px 6px;
    color: #fff;
    text-decoration: none;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    position: relative;
    cursor: pointer;
    border: 1px;
}

.region-content a {
    color: #2c99d2;
    text-decoration: none;
}

.form-submit {
    margin-top: 5px;
    margin-bottom: 5px;
    font-style: normal;
}

.form-item, .form-actions {
    margin-top: 1em;
    margin-bottom: 1em;
}

#user-login .description {
    margin-top: 3px;
}

.form-item .description {
    font-size: .85em;
}

.form-item, .form-actions {
    margin-top: 1em;
    margin-bottom: 1em;
}

.block {
    margin-bottom: 20px;
}

.marker, .form-required {
    color: #f00;
}

tr.odd .form-item,tr.even .form-item {
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap
}

input.form-checkbox,input.form-radio {
    vertical-align: middle
}

.form-item input.error,.form-item textarea.error,.form-item select.error {
    border: 2px solid red
}

.container-inline .form-actions,.container-inline.form-actions {
    margin-top: 0;
    margin-bottom: 0
}


.input-container {
    display: flex;
    flex-direction: column;
    width: 95%;
  }
  
  label {
    width: 100%;
    font-weight: bold;
  }
  
  input {
    width: auto;
  }
 
.warning p {
    margin-top:0px;
}
.primarya li a{
    color:#000000 !important;
}
.login-alert-message {
    background:#f8fff0;
    padding: 10px;
    border: 2px solid #be7;
    margin-bottom: 3%;
}
.form-item {
    width: 60%;
  }
  @media only screen and (max-width: 850px) {
    .login-form-container {
        margin-left: 2%;
        margin-right: 2%;
    }
  }

  @media only screen and (max-width: 500px) {
    .login-alert-message {
        margin-top:3%;
    }
  }  