.cart-page {
  display: flex;
  background-color: #f8f8f8;
  max-width: 1170px;
  margin: 0 auto;
  margin-bottom: 5%;
  clear: both;
}

.cart-details,
.order-summary {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 750px;
  height: auto;
  margin-right: 15px;
}

.select-payment {
  margin-left: 10px;
}

.cart-page-payment-text {
  margin-top: 15px;
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.quantity-container input {
  flex: 0;
}

.cart-table th,
.cart-table td {
  padding: 10px;
  text-align: left;
  vertical-align: top;
}

.cart-table th {
  border-bottom: 2px solid #ddd !important;
  border: none;
}

.cart-table td {
  border-bottom: 1px solid #ddd !important;
  border: none;
}

.cart-table .remove-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #2c99d2;
  text-decoration: none;
  text-align: center;
}

.remove-button {
  display: block;
  margin: auto;
}

.checkout-button1 {
  background-color: #ed202c;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 20%;
  text-align: center;
  margin: 0 0 20px auto;
  font-size: 14px;
  font-weight: 700;
}

.checkout-button1:hover {
  background-color: #c0392b;
}

.checkout-button2 {
  background-color: #ed202c;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
  font-weight: 700;
}

.checkout-button2:hover {
  background-color: #c0392b;
}

.product-items {
  display: inherit;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  color: #003c7d;
}

.your-order-text {
  color: #003c7d;
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 20px;
}

.your-cart-text {
  color: #003c7d;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
  padding-left: 10px;
  font-weight: 100;
}

.cart-note {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
  text-align: right;
  line-height: 1.8em;
}

.order-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 390px;
  height: fit-content;
}

.order-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.cart-page .order-details {
  display: block;
}
.order-item,
.order-total {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.order-total {
  font-size: 21px;
  font-weight: bold;
}

.empty-cart-message {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: auto;
  height: auto;
  margin-right: 15px;
}

.redirect-button {
  width: 46%;
  background-color: #ed202c;
  margin-bottom: 21px;
  line-height: 0;
  padding: 17px 17px;
  margin-left: 14px;
  color: #fff !important;
  border-color: #d43f3a;
  border-radius: 5px;
  box-shadow: none;
  font-size: 14px;
}

.cart-is-empty {
  color: #003c7d;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
  font-weight: 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

.set-message-done {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f8fff0;
  border: 1px solid #be7;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}

.set-message-done .cart-message-icon {
  margin-right: 10px;
  color: #4caf50;
}

.cart-page-dropdowns {
  display: contents;
  color: #777;
}

.select-payment {
  font-weight: 700;
}
.cart-page h2 {
  color: #003c7d;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.text-uppercase {
  text-transform: uppercase;
}
.order-item {
  color: #003c7d;
  font-weight: 700;
}
.cart-page-payment-text .quantity-container input {
  border-right: 1px solid #dbe1dd;
  text-align: center;
}
.cart-page-payment-text .quantity-container-button2 {
  border-right: 1px solid #dbe1dd;
  border-top: 1px solid #dbe1dd;
  border-bottom: 1px solid #dbe1dd;
}
.cart-page-payment-text .quantity-container input {
  height:30.5px;
}
@media (max-width: 850px) {
  .cart-page {
      flex-direction: column;
      max-width: 725px;
  }

  .cart-details {
      width: 100%;
      margin-bottom: 20px;
      height: auto;
  }

  .order-summary {
      width: 100%;
  }
}

@media (max-width: 500px) {
  .checkout-button1,
  .checkout-button2 {
      width: 100%;
  }
  .cart-page {
      flex-direction: column;
      padding: 2%;
      padding-top: 4%;
  }
  .order-summary {
      height: 240px;
  }
  .empty-cart-buttons .redirect-button {
      width: 100%;
      margin-left: 0px;
  }
  .cart-table th,
  .cart-table td {
      font-size: 14px;
      vertical-align: top;
  }
  .cart-details {
      width: 100%;
  }
  .cart-note {
      text-align: left;
  }
  .cart-table {
      table-layout: fixed;
      width: 100%;
  }
  .cart-page .select-payment {
      width: 100%;
      margin-left: 0px;
  }
  .cart-table .product-title {
      width: 60%;
  }
  .cart-page .select-payment {
      color: #000;
      height: 20px;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 0px;
      width: 100%;
  }
}
