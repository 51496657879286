.product-detail-container {
  padding: 10px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f6f5f5;
}

.breadcrumb {
  padding-left: 15px;
  font-size: 14px;
  margin-bottom: 20px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.product-detail-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.product-image {
  flex: 1;
  text-align: center;
}

.product-image img {
  height: 412px;
  width: 355px;
}

.thumbnail-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.thumbnail-container img {
  width: 135px;
  height: 135px;
  margin: 0 5px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.apparel-product-details {
  /* flex: 2; */
  display: flex;
  padding: 10px;
  padding-left: 25px;
}

.apparel-product-details h1 {
  font-size: 22px;
  color: #003c7d;
  margin-top: 0px;
}

.apparel-price {
  color: red;
  font-size: 18px;
  font-weight: bold;
}

.quantity-selector {
  display: -webkit-flex;
}

.quantity-container {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  margin: 20px 0;
  justify-content: left;
}

.quantity-container-button1 {
  /* flex: 1; */
  padding: 4px 10px;
  font-size: 18px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  border-radius: 15px 0px 0px 15px;
  border: 1px solid #dbe1dd;
  margin-right:0px;
}

.quantity-container-button2 {
  /* flex: 1; */
  padding: 4px 10px;
  font-size: 18px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  border-radius: 0px 15px 15px 0px;
  margin-left:0px;

}

.quantity-container input {
  width: 40px;
  font-size: 14px;
  border: none;
  border-top: 1px solid #dbe1dd;
  border-bottom: 1px solid #dbe1dd;
  font-weight: bold;
  
}

.quantity-selector-input {
  text-align: right;
  
  /* text-indent: 6px; */

}

@media (max-width: 1600px) {

  .quantity-selector-input {
      text-align: center;

  }
}


.product-detail-container .quantity-container-button1 {
  border: 0px solid #dbe1dd;
}
.product-detail-container .quantity-container-button2 {
  border: 0px solid #dbe1dd;
}
.product-detail-container .quantity-container input {
  border: 0px solid #dbe1dd;
}
.store-search::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
}
.size-chart-modal table {
  width: 100%;
  border: 1px solid #000;
}
.size-chart-modal table td {
  padding: 10px;
  width: auto;
  border: 1px solid #000;
  border-left: 0px;
  border-right: 0px;
}
.size-title {
  font-weight: bold;
}
.size-select {
  display: block;
  width: 215px;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 26px;
  margin-top: 10px;
}

.apparel-add-to-cart {
  /* display: inline-block;*/
  width: 100%;
  max-width: 150px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 35px;
  background-color: #ed202c;
  color: #fff;
  padding: 7px 24px;
  cursor: pointer;
  margin-left: 40px;
  word-wrap: normal;
}

.description {
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.375;
}

.referral-wallet img {
  width: 265px;
  height: 365px;
}

.apparel-hr {
  border-top: 2px solid #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  height: 0;
}

.apparel-product-disclaimer {
  color: rgb(0, 60, 125);
  text-align: left;
}

.apparel-size-container {
  display: flex;
  flex-direction: row;
}

.apparel-size-modal {
  align-content: space-evenly;
  color: #2c99d2;
  text-decoration: none;
  cursor: pointer;
  margin-left: 10%;
}
.apparel-right-content {
  margin-left: 3%;
  margin-right: 3%;
  flex: 50%;
}
.glider-next,
.glider-prev {
  left: 7px;
}
.glider-next,
.glider-next {
  left: 83%;
}

@media (max-width: 500px) {
  .product-detail-content {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
  }

  .breadcrumb {
      margin-top: 5%;
  }

  .referral-wallet img {
      width: 100%;
      height: auto;
  }
  .apparel-product-details {
      flex-direction: column;
  }
}
