.single-adv-cert-container .single-adv-flex-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
  }
  
  .single-adv-flex-container .single-adv-flex-item-left {
    
    flex: 60%;
  }
  
  .single-adv-flex-container .single-adv-flex-item-right {
   
    flex: 30%;
  }
  .single-adv-cert-header {
    background-color: #003c7d;
  }
  .single-adv-cert-header h1 {
    font-size: 30px;
    color: white;
    margin-top: 20px;
    margin-left: 20px;
    display: inline-block;
    border-left: 15px solid #f3ac3a;
    padding-left: 10px;
  }
  .single-adv-cert-content {
    padding: 20px !important;
    background: #fff;
    font-size: 16px;
  }
  .single-adv-cert-content ul li {
    margin-left: 20px;
    list-style-type: none !important;
    font-size: 16px !important;
    margin-bottom: 15px;
    clear:both;
  }
  .single-adv-cert-content ul li::before {
    content: "\2022";
    color: #f3ac3a;
    width: 25px;
    font-weight: 700;
    font-size: 2.5rem;
    float:left;
    left: 0;
    line-height: 16px;
    margin-bottom: 5px;
}
.single-adv-cert-content ul li::marker {
  content: "";
}
.single_adv_image_content {
  margin: 2% 0;
    position: relative;
}
.single-adv-cert-background {
  width:100%;
  border: 5px solid #fff;
    border-radius: 15px;
    box-shadow: 0 0px 20px -9px;
}
.single-adv-cert-logo {
width: 40%;
    position: absolute;
    top: 16%;
    left: 31%;
}
.single-adv-cert-content .faq-button {
margin: 0 auto;
    text-align: center;
    display: block;
    margin-top:20px;
    margin-bottom:20px;
}
.single-adv-cert-content .enroll_btn {
color: #fff !important;
margin-bottom: 15px;
border: 4px solid #fff;
border-radius: 10px !important;
background: -webkit-linear-gradient(top, #fe0000 0%, #b60000 100%);
font-size: 18px;
box-shadow: 0px 0px 8px #999;
max-width: 100%;
width: 291px;
height: 42px;
font-weight: 700;
cursor: pointer;
margin-top:20px;
}
.single-adv-flex-container {
  margin-top:3%;
}
.certified_content {
font-size: 16px;
color: #333;
}
.certified_content ul li {
list-style: outside none disc !important;
margin-bottom: 15px;
}
.certified_content h2 {
color: #003c7d;
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .red_color {
    color:#FF0000;
  }
  .single-adv-cert-container .linkedin-badge-img img {
    width:auto;
    height:auto;
  }
  .single-adv-cert-container .modal-badge {
    width: 35%;
  }
@media (max-width: 850px) { 
.single-adv-cert-container {
  margin-top:7%;
}
.single-adv-cert-container .single-adv-flex-container {
  row-gap:20px;
  margin-top:7%;
}
.single-adv-cert-container .linkedin-badge-img img {
  width: 180px;
}
.single-adv-cert-container .linkedin-badge-img {
  margin: 25px 0;
}
.single-adv-cert-container .linkedin-info {
  padding-right: 40px;
  padding-left: 40px;
  height: auto;
  padding-bottom: 35px;
}
.single-adv-cert-container .full-width-header h2 {
  width:85%;
}
.single-adv-cert-container #state {
  color:rgb(51, 51, 51);
  height:35px;
}
}
